import { parse, parseJSON, sub, isDate } from 'date-fns'
import { format } from 'date-fns-tz'

const defaultDateTimeFormat = 'MM/dd/yyyy HH:mm';
const defaultDateFormat = 'MM/dd/yyyy';

const DateTimeParser = {
  toLocalJSONFormat(date) {
    const parsedDate = new Date(date);
    const offset = Math.abs(parsedDate.getTimezoneOffset() / 60);
    const localJSONFormat = parsedDate.setHours(parsedDate.getHours() + offset);
    return new Date(localJSONFormat).toJSON();
  },

  setSOD(date) {
    return new Date(date).setHours(0, 0, 0, 0);
  },

  setEOD(date) {
    return new Date(date).setHours(23, 59, 59);
  },

  toLocaleDateString(date) {
    const dateObj = new Date(date);
    const result = format(dateObj, defaultDateFormat)
    return result;
  },

  toLocaleDateTimeString(jsonFormatDateString) {
    const dateObj = parseJSON(jsonFormatDateString);
    const result = format(dateObj, defaultDateTimeFormat);
    return result;
  },

  toDateObject(value) {
    const result = parse(value, 'MM/dd/yyyy', new Date());
    return result;
  },

  isValidFormat(date, format) {
    if (format === undefined || format === null) 
    {
      return (parseJSON(date).toString() !== 'Invalid Date');
    }
    else {
      return (parse(date, format, new Date()).toString() !== 'Invalid Date');
    }
  },

  subtract(startDate, years, months, days) {
    const parsedStartDate = new Date(startDate);
    const result = sub(
      parsedStartDate,
      {
        years: years,
        months: months,
        days: days
      }
      )
    return result;
  },

  getCurrentCultureCode() {
    return 'en';
  }
};
export default DateTimeParser;
