import React, { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { useClassNames } from '../../hooks/useClassNames';

export default function MenuContextList({ actions, element, menuUpVariant }) {
  const classNames = useClassNames();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Menu as="div" className="ml-3 inline-block text-left">
        <div>
          <Menu.Button className="-my-2 p-2 bg-white flex items-center hover:text-gray-600 focus:outline-none border border-gray-300 rounded-md">
            <span className="sr-only">Open options</span>
            <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={classNames.classNames(
              menuUpVariant ? 'bottom-5' : 'top-0',
              'absolute right-0 mt-2 mr-10 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-100')}
          >
            <div className="py-1">
              {actions.map((action, i) => (
                <Menu.Item key={`key-${i}`}>
                  {({ active }) =>
                    action.href && action.newWindow ? (
                      <a
                        href={action.href}
                        target='_blank'
                        rel="noreferrer"
                        className={classNames.classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'w-full flex justify-between px-4 py-2 text-sm'
                        )}
                      >
                        {action.name}
                      </a>
                    ) : action.href ? (
                      <NavLink
                        to={action.href}
                        className={classNames.classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'w-full flex justify-between px-4 py-2 text-sm'
                        )}
                      >
                        {action.name}
                      </NavLink>
                    ) : (
                      <button
                        key={`action-${i}`}
                        type="button"
                        className={classNames.classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'w-full flex justify-between px-4 py-2 text-sm',
                          action.textClassNames
                        )}
                        disabled={action.disabled}
                        onClick={
                          action.name === 'Delete2' ? () => setShowModal(true) : action.onClick
                        }
                        to={action.href}
                      >
                        {action.name}
                      </button>
                    )
                  }
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}
