import ListItem from './ListItem';

const List = ({ parentId, options, folders, searchValue, onChange, ...props }) => {
  return (
    <ul className="flex-grow max-h-32 overflow-y-auto">
      {options &&
        options
          .filter((o) =>
            searchValue.length > 0 ? o.name.toLowerCase().includes(searchValue) : true
          )
          .map((o) => (
            <ListItem
              key={o.value}
              parentId={parentId}
              option={o}
              folders={folders}
              values={props.values}
              onChange={onChange}
            />
          ))}
    </ul>
  );
};

export default List;
