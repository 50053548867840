import { CheckIcon, ChevronRightIcon, MinusSmIcon } from '@heroicons/react/outline';
import { useClassNames } from '../../../hooks/useClassNames';
import useCountChildren from '../hooks/useCountChildren';
import useOptionHandler from '../hooks/useOptionHandler';
import { useEffect } from 'react';

const ListItem = ({ parentId, option, folders, onChange, ...props }) => {
  const { classNames } = useClassNames();
  const [countChildren, setFolders] = useCountChildren();
  const optionHandler = useOptionHandler(parentId, folders, props.values);

  const handleOnOptionClick = (option, isClickCheckbox) => {
    const onClickResult = optionHandler.onClick(option, isClickCheckbox);
    onChange(onClickResult);
  };

  const renderStatus = (id) => {
    const children = countChildren(id);
    const valuesFiltered = props.values.filter((v) => children.includes(v));

    if (children.length > 0) {
      if (props.values.includes(id)) {
        // || children.length === valuesFiltered.length
        return <CheckIcon className="mr-2 h-4 w-4 border border-gray-500 rounded" />;
      } else if (
        (children.length > valuesFiltered.length && valuesFiltered.length > 0) ||
        children.length === valuesFiltered.length
      ) {
        return <MinusSmIcon className="mr-2 h-4 w-4 border border-gray-500 rounded" />;
      } else {
        return (
          <span className="mr-2 block border h-4 w-4 text-indigo-600 border-gray-500 rounded focus:ring-indigo-500" />
        );
      }
    } else if (props.values.includes(id)) {
      return <CheckIcon className="mr-2 h-4 w-4 border border-gray-500 rounded" />;
    } else {
      return (
        <span className="mr-2 block border h-4 w-4 text-indigo-600 border-gray-500 rounded focus:ring-indigo-500" />
      );
    }
  };

  useEffect(() => {
    setFolders(folders);
  }, [folders]);

  return (
    <li
      className={classNames(
        'flex flex-grow items-center px-3 py-1.5 cursor-pointer hover:bg-gray-200'
      )}
    >
      <div className="flex flex-grow">
        <div
          className="flex content-center items-center"
          onClick={() => handleOnOptionClick(option, true)}
        >
          {renderStatus(option.value)}
        </div>

        <div className="flex-grow" onClick={() => handleOnOptionClick(option)}>
          {option.name}
        </div>
      </div>

      {folders.find((f) => option.value && f.parentId === option.value) && (
        <div onClick={() => handleOnOptionClick(option)}>
          <ChevronRightIcon className="ml-auto h-4 w-4" />
        </div>
      )}
    </li>
  );
};

export default ListItem;
