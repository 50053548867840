import { Form, Formik } from 'formik';
import DateTimeParser from '../../../../utils/DateTimeParser';
import DateField from '../../../../components/DateField';
import { Button } from '../../../../components';
import { FormikSubmitButton } from '../../../../components/Form/Formik/FormikSubmit';
import MultipleSelectField from '../../../../components/MultipleSelectField';
import FormikInput from '../../../../components/Form/Formik/FormikInput';
import { useEffect, useState } from 'react';
import { TrashIcon } from '@heroicons/react/outline';

export const formInitialValues = {
  createdFrom:  DateTimeParser.toLocaleDateString(DateTimeParser.subtract(new Date(), 1, 0, 0)),
  createdTo: DateTimeParser.toLocaleDateString(new Date()),
  foldersIds: []
};

const Filter = ({ metadataColumns, onSubmit }) => {
  const [parentId, setParentId] = useState(null);
  const [options, setOptions] = useState([]);

  const handleOnSubmitForm = (values, setSubmitting) => {
    onSubmit(
      {
        createdFrom: values.createdFrom
          ? DateTimeParser.toLocalJSONFormat(DateTimeParser.setSOD(values.createdFrom))
          : null,
        createdTo: values.createdTo
          ? DateTimeParser.toLocalJSONFormat(DateTimeParser.setEOD(values.createdTo))
          : null,
        foldersIds: values.foldersIds,
        searchQuery: values.searchQuery
      },
      setSubmitting
    );
  };

  const onClickDeleteDateRequirment = (fieldName, setValueFunction, props) => {
    setValueFunction(fieldName, '')
  };

  useEffect(() => {
    let folders = metadataColumns.folders
      .filter((f) => f.parentId === parentId)
      .map((f) => ({
        name: f.name,
        value: f.id
      }));

    if (!parentId) {
      folders = [{ name: 'Root folder', value: null }, ...folders];
    }

    setOptions(folders);
  }, [metadataColumns, parentId]);

  return (
    <>
      <Formik
        initialValues={formInitialValues}
        onReset={(values, actions) => {
          handleOnSubmitForm(values, actions.setSubmitting);
        }}
        onSubmit={(values, actions) => {
          handleOnSubmitForm(values, actions.setSubmitting);
        }}
      >
        {(props) => (
          <Form>
            <div className="w-full mt-5 mb-10 pb-5 border-b border-gray-200">
              <div className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-4 lg:grid-cols-6">
                <div className="col-span-1 sm:col-span-1">
                  <FormikInput label="Search query" name="searchQuery" formikProps={props} />
                </div>

                <div className="col-span-1 sm:col-span-18 grid grid-cols-6">
                  <div className="col-span-5">
                    <DateField
                      navbar={true}
                      dataCy="createdFrom"
                      label="Date from"
                      name="createdFrom"
                      value={props.values.createdFrom}
                      maxDate={props.values.createdTo}
                      onChange={(v, e) => {
                        props.setFieldValue('createdFrom', v);
                      }}
                    />
                  </div>
                  <div>
                    <button type="button" className="mt-8 ml-2" onClick={() => onClickDeleteDateRequirment('createdFrom', props.setFieldValue, props)} aria-label="Disable Date From filter">
                      <TrashIcon className="h-5 w-5 text-gray-500"/>
                    </button>
                  </div>

                </div>

                <div className="col-span-1 sm:col-span-1 grid grid-cols-6 cursor-pointer">
                  <div className="col-span-5">
                    <DateField
                      navbar={true}
                      dataCy="createdTo"
                      label="Date to"
                      name="createdTo"
                      value={props.values.createdTo}
                      minDate={props.values.createdFrom}
                      onChange={(v, e) => {
                        props.setFieldValue('createdTo', v);
                      }}
                    />
                  </div>
                  <div>
                    <button type="button" className="mt-8 ml-2" onClick={() => onClickDeleteDateRequirment('createdTo', props.setFieldValue, props)} aria-label="Disable Date To filter">
                      <TrashIcon className="h-5 w-5 text-gray-500"/>
                    </button>
                  </div>
                </div>

                <div className="col-span-1 sm:col-span-1">
                  <MultipleSelectField
                    label="Folders"
                    name="foldersIds"
                    customClassNames="w-60"
                    parentId={parentId}
                    values={props.values.foldersIds}
                    folders={metadataColumns.folders}
                    options={options}
                    onChange={(values) => {
                      setParentId(values.parentId);
                      props.setFieldValue('foldersIds', values.foldersIds);
                    }}
                    onParentIdChange={() => {
                      const folder = metadataColumns.folders.find((f) => f.id === parentId);
                      setParentId(folder.parentId);
                    }}
                  />
                </div>
              </div>

              <div className="flex justify-end mt-6">
                <Button variant={Button.variants.secondary} onClick={props.handleReset}>
                  Reset
                </Button>

                <FormikSubmitButton
                  id="search"
                  text="Search"
                  className="ml-2 i"
                  formikProps={props}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Filter;
