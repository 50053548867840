import { useContext } from 'react';
import './styles.scss';
import { config } from '../../utils/config';
import { OidcRoutesContext } from '../../contexts';
import { MenuBar } from '@metaforcelabs/metaforce-core';
import { Link } from 'react-router-dom';

const AdminLayout = ({ children }) => {
  const oidcRoutesContext = useContext(OidcRoutesContext);

  const navigationMenu = [
    {
      linkElement: <Link href="/">Home</Link>,
      name: "Home",
      current: true,
      featureEnabled: true
    }
  ];

  return (
    <>
      <div className="fixed flex flex-col bg-gray-50 h-screen w-screen overflow-auto">
        <MenuBar
          subMenuNav={'releasePackages'}
          navigation={navigationMenu}
          homeButtonLink={config.centerpointUiBaseUrl}
          customerName={oidcRoutesContext.customerInfo.name}
          customerOrgNumber={oidcRoutesContext.customerInfo.orgNumber}
          userProfileImage={oidcRoutesContext.userProfile.userProfileImage}
          userName={oidcRoutesContext.userProfile.fullName}
          helpLink={config.docsUrl}
        />
        {children}
      </div>
    </>
  );
};

export default AdminLayout;
