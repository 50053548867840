import { download, get, post, destroy } from './api';

export const getAllDocumentsApi = async (data) => {
  return await post(`Archive/GetAllDocuments`, data);
};

export const getCompanyMetaDataColumnsApi = async () => {
  return await get(`Archive/GetCompanyMetaDataColumns`);
};

export const getFileApi = async (fileId) => {
  return await download(`Archive/${fileId}`);
};

export const rollbackFileApi = async (data) => {
  return await post(`Archive/Rollback`, data);
};

export const deleteFile = async (fileId) => {
  return await destroy(`Archive/${fileId}`);
};
