import { SubmitButton } from '../SubmitButton';

export const FormikSubmitButton = ({ text, className, formikProps, id }) => {
  return (
    <SubmitButton
      text={text}
      className={className}
      disabled={
        (Object.keys(formikProps.touched).length > 0 &&
          Object.keys(formikProps.errors).length > 0) ||
        formikProps.isSubmitting
      }
      id={id}
    />
  );
};
